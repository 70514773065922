<template>
  <v-card>
    <v-card-title><h4>Organizations</h4></v-card-title>
    <v-data-table
      :headers="headers"
      :items="items.items"
      :server-items-length="items.meta.total"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon dense small>{{ icons.mdiPlus }}</v-icon
                >&nbsp;New Organization
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="edit">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Name"
                          required
                          v-model="editedItem.name"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Address"
                          required
                          v-model="editedItem.address"
                          :error-messages="addressErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Contact Name"
                          required
                          v-model="editedItem.contact_name"
                          :error-messages="contactNameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Contact Email"
                          type="email"
                          placeholder="john@example.com"
                          required
                          v-model="editedItem.contact_email"
                          :error-messages="contactEmailErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="editedItem.applications"
                          :items="applications"
                          label="Applications"
                          multiple
                          chips
                          required
                          :error-messages="applicationErrors"
                          ><template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save"> Save </v-btn>
                <v-btn color="secondary" outlined @click="close">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5 text-break text-center"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.contact_email="{ item }">
        <a :href="item.contact_email">{{ item.contact_email }}</a>
      </template>
      <template v-slot:item.applications="{ item }">
        <v-chip
          v-for="application in item.applications"
          :key="application.name"
        >
          {{ application.name }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon small @click="deleteItem(item)"> {{ icons.mdiDelete }} </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataFromApi"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiPlus, mdiDelete } from "@mdi/js"
import { mapActions, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  validations() {
    if (this.editedIndex == -1) {
      return {
        editedItem: {
          name: { required },
          address: { required },
          contact_name: { required },
          contact_email: { required, email },
          applications: { required },
        },
      }
    } else {
      return {
        editedItem: {
          name: { required },
          address: { required },
          contact_name: { required },
          contact_email: { required, email },
          applications: { required },
        },
      }
    }
  },
  data() {
    return {
      loading: true,
      total: 0,
      options: {},

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id: -1,
        name: "",
        address: "",
        contact_name: "",
        contact_email: "",
        applications: [],
      },
      defaultItem: {
        id: -1,
        name: "",
        address: "",
        contact_name: "",
        contact_email: "",
        applications: [],
      },
      icons: { mdiPencil, mdiPlus, mdiDelete },
    }
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Address", sortable: false, value: "address" },
        { text: "Contact name", sortable: false, value: "contact_name" },
        { text: "Contact email", sortable: false, value: "contact_email" },
        {
          text: "Applications",
          sortable: false,
          value: "applications",
          align: "center",
        },
        { text: "Actions", sortable: false, value: "actions", align: "center" },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Organization" : "Edit Organization"
    },
    applications() {
      return this.apps.items.map(item => item.name)
    },

    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push("Name is required.")
      return errors
    },
    addressErrors() {
      const errors = []
      if (!this.$v.editedItem.address.$dirty) return errors
      !this.$v.editedItem.address.required &&
        errors.push("Address is required.")
      return errors
    },
    contactNameErrors() {
      const errors = []
      if (!this.$v.editedItem.contact_name.$dirty) return errors
      !this.$v.editedItem.contact_name.required &&
        errors.push("Contact Name is required.")
      return errors
    },
    contactEmailErrors() {
      const errors = []
      if (!this.$v.editedItem.contact_email.$dirty) return errors
      !this.$v.editedItem.contact_email.required &&
        errors.push("Contact Email is required.")
      !this.$v.editedItem.contact_email.email &&
        errors.push("Must be valid e-mail")

      return errors
    },
    applicationErrors() {
      const errors = []
      if (!this.$v.editedItem.applications.$dirty) return errors
      !this.$v.editedItem.applications.required &&
        errors.push("You must select an application")

      return errors
    },
    ...mapState({
      items: state => state.organizations.organizations,
      apps: state => state.applications.applications,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        const start = (this.options.page - 1) * this.options.itemsPerPage
        this.loadOrganizations({
          start: start,
          limit: this.options.itemsPerPage,
        }).then(() => {
          this.loading = false
        })
      })
    },

    editItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.applications = this.editedItem.applications.map(
        item => item.name
      )
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      this.deleteOrganization(this.editedItem.id).then(() => {
        this.loading = false
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        if (this.editedIndex > -1) {
          this.editOrganization(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        } else {
          this.addOrganization(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        }
        this.close()
      }
    },
    ...mapActions("organizations", [
      "loadOrganizations",
      "addOrganization",
      "editOrganization",
      "deleteOrganization",
    ]),
    ...mapActions("applications", ["loadApplications"]),
  },
  mounted() {
    this.loadApplications({ limit: -1 })
  },
}
</script>
